import React from 'react';
import { useParams } from 'react-router-dom'
function Invite() {
    const { username } = useParams();
    const [user, setUser] = React.useState();
    React.useEffect(() => {
        if (!user) {
            fetch(`${process.env.REACT_APP_API_URL}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username
                })
            })
                .then((response) => response.json())
                .then((data) => setUser(data))
        }
    }, [user])
    const d = user && user.createdAt && new Date(user && user.createdAt)
    const ye = d && new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = d && new Intl.DateTimeFormat('en', { month: 'long' }).format(d)

    const memberSince = `${mo} ${ye}`
    return (
        <header className="masthead">
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-lg-7 my-auto">
                        <div className="header-content mx-auto">
                            <h1 className="mb-5">You have been invited to share your soundtrack on Volley Music!</h1>
                            <a className="badge-link" href="https://apps.apple.com/us/app/volley-music/id1402819460">
                                <img src="/img/app-store-badge.svg" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-5 my-auto">
                        <img src={user && user.image} className="img-fluid" alt="" />
                        <h2 className="mb-5">By: {user && user.username}</h2>
                        <h3 className="mb-5">member since {memberSince}</h3>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Invite;